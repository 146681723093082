body {
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  h1 {
    font-size: 2em;
    margin-top: 1em;
  }
  
  #percentage {
    font-size: 5em;
    margin-top: 1em;
    color:green;
    font-weight: 900;
  }
  
  #remainingDays{
    font-size: 5em;
    margin-top: 1em;
    color:green;
    font-weight: 900;
  }